import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { RouteComponentProps } from 'react-router-dom';
import { setStorageData } from "../../../framework/src/Utilities";


export interface MessageData {
  attributes: {
    body: string
  }
}

export interface ResponseData {
  id: number;
  attributes: {
    first_name: string;
    last_name: string;
  }
}

export interface LocationState {
  detail: string;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: string;
  id: string;
  // Customizable Area Start
  history: RouteComponentProps["history"];
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  token: string;
  enableField: boolean;
  // Customizable Area Start
  itemId: string | null;
  result: MessageData[];
  message: string;
  name: string | null;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SelfdestructMessagingParticularUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = ""
  getSendMsgId: string = ""
  getMessageListId: string = ""
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.login = this.login.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      // Customizable Area Start
      message: "",
      result: [],
      itemId: this.itemID,
      name: this.name,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSendMsgId) {
        if (responseJson) {
          return true
        }
        else {
          return false
        }
      }

      else if (apiRequestCallId === this.getDataCallId) {
        if (responseJson) {
          this.setState({
            result: [...responseJson?.data]
          })
        }
      }
      else if (apiRequestCallId === this.loginApiCallId) {
        
        this.setState({
          token: responseJson.meta.token
        });
        this.getMessageList();
        await setStorageData("token", responseJson.meta.token);
      }

    }
  }

  // Customizable Area Start


  locationState = this.props.history?.location?.state;
  itemID = (this.locationState as LocationState)?.detail;
  name = (this.locationState as LocationState)?.name;

  clearMessage = () => {
    this.setState({ message: "" })
  }

  handleInputChange = (event: { target: { value: string; }; }) => {
    this.setState({
      message: event?.target.value
    })
  }

  handleSendMessage = () => {
    const newMessage: MessageData = {
      attributes: {
        body: String(this.state.message),
      },
    }
    this.setState({
      result: [...this.state.result, newMessage],
    })
  }


  getMessageList = () => {
    const header = {
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_selfdestructmessaging/messages.json?recipient_id=${this.state.itemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendMessage = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }
    const body = {
      data: {
        attributes: {
          body: this.state.message
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSendMsgId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_selfdestructmessaging/messages.json?recipient_id=${this.state.itemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // Customizable Area End


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };


  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };


  login = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const header = {
      "Content-Type": "application/json",
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logins.json`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async componentDidMount() {
    // Customizable Area Start
    this.login();
    // Customizable Area End

  }
}
