import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "../assets/SelfdestructMessagingParticularUser.css"
import {  RouteComponentProps , withRouter } from 'react-router-dom';



interface Props extends RouteComponentProps {
  navigation: string;
  id: string;
}

// Customizable Area End

import SelfdestructMessagingParticularUserController, {
  MessageData, ResponseData,
} from "./SelfdestructMessagingParticularUserController.web";

export class SelfdestructMessagingParticularUser extends SelfdestructMessagingParticularUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>    
        <div className = "containerClass" id = "username">
          <h3>{this.state?.name}</h3>
        </div>

      {
          this.state.result.map((response:MessageData , index : number)=>{
            return (
              <div key = {index} className= "containerClass">
                <div className="singleUser">
                      <div className="userImg">
                        <AccountCircleIcon/>
                      </div>
            
                      <div className="userName">
                        <h5>{response.attributes?.body}</h5>
                      </div>
                </div>
               </div>
            )
          })
      }

        <div className="footer">
          <div>
            <input placeholder="Send message...." value = {this.state.message} className="inputBar" id = "inputSearch" onChange={this.handleInputChange} />  
            <button id = "submitBtn" onClick = {(event)=>{this.handleSendMessage(); this.sendMessage(); this.clearMessage();}} className="btn">Send</button>
          </div>
        </div>
        
        
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withRouter(SelfdestructMessagingParticularUser)
// Customizable Area End
