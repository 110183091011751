import React from "react";
// Customizable Area Start
import { Line, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
// Customizable Area End

import BaselineReportingController, {
  Props, optionsForHorizontal,
} from "./BaselineReportingController.web";

export default class BaselineReporting extends BaselineReportingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
  render() {

    return (
      // Customizable Area Start
      <>

        <div style={this.styles.containerClass}>
          <div style={this.styles.chartContainer}>
            <div style={this.styles.charts}>
              <div>
                <select onChange={(event)=>{event.target.value=="date"? this.handleDatePicker("dateCheckArea"):this.clearDatePicker("dateCheckArea"); this.getData(event.target.value,"dataForArea")}} data-testid="dataForArea" name="dataForArea" id="dataForArea">
                  <option value="today">Today</option>
                  <option value="week">Week</option>
                  <option selected value="month">Month</option>
                  <option value="date" id="datePickLine" onClick={() => { this.handleDatePicker("dateCheckArea") }} >Pick a Date</option>
                </select>
                {
                  this.state.dateCheckArea ? <><input type="date" onChange={this.handleFromDateChange} /> <input type="date" onChange={this.handleToDateChange} /> <button id="btnclick" onClick={() => { this.getData("date", "dataForArea") }} style={this.styles.submitButton}>Submit</button></> : null
                }
                <Line data={this.state.dataForArea} options={this.state.options} />
              </div>
              <br />
              <div>
                <select onChange={(event)=>{event.target.value=="date"? this.handleDatePicker("dateCheckPie"):this.clearDatePicker("dateCheckPie");  this.getData(event.target.value,"dataForPie")}} data-testid="dataForPie"  name="dataForPie" id="dataForPie">
                  <option value="today">Today</option>
                  <option value="week">Week</option>
                  <option selected value="month">Month</option>
                  <option value="date" id="datePickPie" onClick={() => { this.handleDatePicker("dateCheckPie") }}>Pick a Date</option>
                </select>
                {
                  this.state.dateCheckPie ? <><input type="date" onChange={this.handleFromDateChange} /> <input type="date" onChange={this.handleToDateChange} /> <button id="btnclickPie" onClick={() => { this.getData("date", "dataForPie") }} style={this.styles.submitButton}>Submit</button></> : null
                }
                <Pie data={this.state.dataForPie} />
              </div>
              <br />
              <div>
                <select onChange={(event)=>{event.target.value=="date"? this.handleDatePicker("dateCheckBar"): this.clearDatePicker("dateCheckBar"); this.getData(event.target.value,"dataForBar")}} data-testid="dataForBar" name="dataForBar" id="dataForBar" >
                  <option value="today">Today</option>
                  <option value="week">Week</option>
                  <option selected value="month">Month</option>
                  <option value="date" id="datePickBar" onClick={() => { this.handleDatePicker("dateCheckBar") }}>Pick a Date</option>
                </select>
                {
                  this.state.dateCheckBar ? <><input type="date" onChange={this.handleFromDateChange} /> <input type="date" onChange={this.handleToDateChange} /> <button id="btnclickBar" onClick={() => { this.getData("date", "dataForBar") }} style={this.styles.submitButton}>Submit</button></> : null
                }
                <Bar data={this.state.dataForBar} options={this.state.options} />
              </div>
              <br />
              <div>
                <select onChange={(event)=>{event.target.value=="date"? this.handleDatePicker("dateCheckHorizontalBar"):this.clearDatePicker("dateCheckHorizontalBar");  this.getData(event.target.value,"dataForHorizontal")}} data-testid="dataForHorizontal" name="dataForHorizontal" id="dataForHorizontal" >
                  <option value="today" >Today</option>
                  <option value="week">Week</option>
                  <option selected value="month">Month</option>
                  <option value="date" id="datePickHorizontal" onClick={() => { this.handleDatePicker("dateCheckHorizontalBar") }}>Pick a Date</option>
                </select>
                {
                  this.state.dateCheckHorizontalBar ? <><input type="date" onChange={this.handleFromDateChange} /> <input type="date" onChange={this.handleToDateChange} /> <button id="btnclickHorizontal" onClick={() => { this.getData("date", "dataForHorizontal") }} style={this.styles.submitButton}>Submit</button></> : null
                }
                <HorizontalBar data={this.state.dataForHorizontal} options={optionsForHorizontal} />
              </div>
              <br />
              <div>
                <select onChange={(event)=>{event.target.value=="date"? this.handleDatePicker("dateCheckLine"): this.clearDatePicker("dateCheckLine"); this.getData(event.target.value,"dataForLine")}} data-testid="dataForLine" name="dataForLine" id="dataForLine">
                  <option value="today">Today</option>
                  <option value="week">Week</option>
                  <option selected value="month">Month</option>
                  <option value="date" id="datePickArea" onClick={() => { this.handleDatePicker("dateCheckLine") }}>Pick a Date</option>
                </select>
                {
                  this.state.dateCheckLine ? <><input type="date" onChange={this.handleFromDateChange} /> <input type="date" onChange={this.handleToDateChange} /> <button id="btnclickForLine" onClick={() => { this.getData("date", "dataForLine") }} style={this.styles.submitButton}>Submit</button></> : null
                }
                <Line data={this.state.dataForLine} options={this.state.options} />;
              </div>
            </div>
          </div>
        </div>


      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
