// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BaselineReporting from "../../blocks/BaselineReporting/src/BaselineReporting";
import SelfdestructMessagingParticularUser from "../../blocks/SelfdestructMessaging/src/SelfdestructMessagingParticularUser";
import SelfdestructMessaging from "../../blocks/SelfdestructMessaging/src/SelfdestructMessaging";

const routeMap = {
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },


  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  BaselineReporting: {
    component: BaselineReporting,
    path: "/BaselineReporting",

  },

  SelfdestructMessaging: {
    component: SelfdestructMessaging,
    path: "/SelfdestructMessaging",
  },
  SelfdestructMessagingParticularUser: {
    component: SelfdestructMessagingParticularUser,
    path: "/SelfdestructMessagingParticularUser",
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
