import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface Options {
  responsive: boolean;
  plugins: {
    legend: {
      position: "top";
    };
    title: {
      display: boolean;
      text: string;
    };
  };

}
export interface DataInfo {
  labels: string[];
  datasets: {
    fill: boolean;
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

export interface DataForLine {
  labels: string[];
  datasets: {
    fill: boolean;
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

export const optionsForHorizontal = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

export const optionsForLine = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const
    },
    title: {
      display: true,
      text: "Chart.js Line Chart"
    }
  }
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Handle {
  x?: string | number;
  y?: string | number;
  width?: string | number;
  height?: string | number;
  rx?: string | number;
  ry?: string | number;
  opacity?: string | number;
}

interface Types {
  labels: string[],
  datasets: [{
    data: number[],
    color: (opacity: number) => string,
    strokeWidth: number,
  }]
}


interface Marked {
  marked: boolean,
  color: string,
  textColor: string,
  startingDay: boolean,
  endingDay: boolean,
}

type MarkedType = {
  [keyName: string]: Marked;
};



interface PieData {
  name: string,
  population: number,
  color: string,
  legendFontColor: string,
  legendFontSize: number
}

interface DropType {
  label: string,
  value: string
}

interface S {
  enableField: boolean;
  // Customizable Area Start
  handleTip: Handle
  showCalendar: boolean
  startDay: string,
  endDay: string,
  dateCheckArea: boolean;
  dateCheckBar: boolean;
  dateCheckPie: boolean;
  dateCheckHorizontalBar: boolean;
  dateCheckLine: boolean
  options: Options;
  // dataInfo : DataInfo;
  dataForLine: DataForLine;
  labels: string[];
  fromDate: string;
  toDate: string;
  dataForArea: DataInfo;
  dataForPie: DataInfo;
  dataForBar: DataInfo
  dataForHorizontal: DataInfo;
  activeState: string;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BaselineReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  styles = {
    containerClass: {
      backgroundColor: "#f1f1f1",
      width: "100%",
      heigth: "100vh"
    },
    chartContainer: {
      padding: "10px",
      color: "red",
      disply: "flex",
    },
    charts: {
      width: "1000px",
      height: "500px",
      alignItem: "center",
    },
    submitButton: {
      width: "100px",
      height: "40px",
      marginTop: "5px"
    }
  };
  getDataCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      activeState: "",
      enableField: false,
      // Customizable Area Start
      fromDate: "",
      toDate: "",
      handleTip: {},
      showCalendar: false,
      startDay: '',
      endDay: '',
      dateCheckArea: false,
      dateCheckBar: false,
      dateCheckPie: false,
      dateCheckHorizontalBar: false,
      dateCheckLine: false,
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      },
      dataForArea: {
        labels: [],
        datasets: [
          {
            fill: true,
            label: 'Dataset 1',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },
      dataForPie: {
        labels: [],
        datasets: [
          {
            fill: true,
            label: 'Dataset 2',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },
      dataForBar: {
        labels: [],
        datasets: [
          {
            fill: true,
            label: 'Dataset 3',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },
      dataForHorizontal: {
        labels: [],
        datasets: [
          {
            fill: true,
            label: 'Dataset 2',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },

      dataForLine: {
        labels: [],
        datasets: [
          {
            fill: false,
            label: 'Dataset 2',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
        ],
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getDataCallId) {
        if (responseJson) {

          let newData = {
            labels: this.state.labels,
            datasets: [
              {
                fill: true,
                label: 'Dataset 2',
                data: responseJson.data,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }
          if (this.state.activeState == "all") {
            this.setState({
              dataForArea: newData,
              dataForPie: newData,
              dataForBar: newData,
              dataForHorizontal: newData,
            })
            const copy = { ...newData }
            this.setState({
              dataForLine: {
                ...newData, datasets: [
                  {
                    ...newData.datasets[0],
                    fill: false,
                  },
                ],
              }
            })

          }
          if (this.state.activeState == "dataForArea") {
            this.setState({
              dataForArea: newData
            })
          }
          else if (this.state.activeState == "dataForPie") {
            this.setState({
              dataForPie: newData
            })
          }
          else if (this.state.activeState == "dataForBar") {
            this.setState({
              dataForBar: newData
            })
          }
          else if (this.state.activeState == "dataForHorizontal") {
            this.setState({
              dataForHorizontal: newData
            })
          }
          else if (this.state.activeState == "dataForLine") {
            newData.datasets[0].fill = false;
            this.setState({
              dataForLine: newData
            })
          }
        }
        else {
          return false
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }


  getData = (duration: string, type: string) => {

    this.setState({ activeState: type })
    if (duration == "today" || duration == "week") {
      this.setState({ labels: ["Mon", "Tue", "Wed", "Thur", "Fri", "sat", "sun"] })
    }
    else if (duration == "month" || duration == "date") {
      this.setState({ labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] })
    }
    const header = {
      "Content-Type": "application/json",
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_baselinereporting/baseline_reportings/total_sos_count'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    const Data = {
      "timeframe": duration == "today" || duration == "week" || duration == "month" ? duration : {
        "start_date": this.state.fromDate,
        "end_date": this.state.toDate
      }
    };
    if (duration == "date" && this.state.fromDate != "" && this.state.fromDate != "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(Data),
      );
      this.setState(() => ({
        fromDate: "",
        toDate: ""
      }))
    } if(duration == "today" || duration == "week" || duration == "month" ) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(Data),
      );
      this.setState(() => ({
        fromDate: "",
        toDate: ""
      }))
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    this.getData("month", "all");
  }




  // web events


  // Customizable Area Start

  handleFromDateChange = (event: { target: { value: string; }; }) => {
    const fromDate = event?.target.value;
    this.setState({
      fromDate
    })

  }

  handleToDateChange = (event: { target: { value: string } }) => {
    const toDate = event?.target.value
    this.setState({
      toDate
    })


  }

  handleDatePicker = (dateName: string) => {
    this.setState((prev) => ({
      ...prev,
      fromDate: "",
      toDate: "",
      [dateName]: true,
    }))
  }

  clearDatePicker = (dateName: string) => {
    this.setState((prev) => ({
      ...prev,
      fromDate: "",
      toDate: "",
      [dateName]: false,
    }))
  }
  // Customizable Area End
}
