import React from "react";


// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withRouter , RouteComponentProps } from 'react-router-dom';
import  '../assets/SelfDestructMessaging.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

  interface Props extends RouteComponentProps {
    navigation: string;
    idd: string;
    response?: ResponseData;
  }

// Customizable Area End

import SelfdestructMessagingController, {
  ResponseData,
} from "./SelfdestructMessagingController.web";

export class SelfdestructMessaging extends SelfdestructMessagingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
          <div className = "containerClass">
              <h3>User-List</h3>
          </div>
    {
      this.state.result.map((response : ResponseData , index: number )=>{
        return (
          <div key={index} className="containerClass">
              <div className="singleUser">
                    <div className="userImg">
                      <AccountCircleIcon/>
                    </div>
                    
                    <div className="userName" id = "handleNavigate" onClick = {()=>{this.handleNavigation(response?.id , response?.attributes)}}>
                          <h5>{response?.attributes?.first_name} {response?.attributes?.last_name}</h5>
                    </div>
              </div>
          </div>
          )
      })
    }      
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withRouter(SelfdestructMessaging )
// Customizable Area End

